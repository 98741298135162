import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import Location from '../utils/Location';
import {
  OutcomeWiseScores,
  OutcomeCategories,
  monthlyGPScoreSummaryTable,
  monthlyGPScoreHeading,
  gpHistoryTable,
  AccuracyScore,
  scores
} from 'data/dashboard/gpdata';

import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Accordion from 'react-bootstrap/Accordion';
import HistoryChart from '../psscore/HistoryChart';
import { useLocation } from 'react-router-dom';
import OutcomeScores from './OutcomeScores';
import ScoreWidget from '../utils/scoreWidget';
import DetailsTable from '../utils/DailyDetails';
import AccuracyDistrib from './Accuracy';

const GPScoreDetails = () => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <Location stateParm={state} />

      <Row className="mb-3 d-flex justify-content-center">
        <Col lg={4}>
          <Row>
            <ScoreWidget dataDump={scores.gpScore} heading="GP Score" />
          </Row>
          <AccuracyDistrib datadump={AccuracyScore} />
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <OutcomeScores
                categories={OutcomeCategories}
                datadump={OutcomeWiseScores}
              ></OutcomeScores>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Details</Accordion.Header>
              <AccordionBody>
                <DetailsTable
                  heading={monthlyGPScoreHeading}
                  data={monthlyGPScoreSummaryTable}
                />
              </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>History</Accordion.Header>
              <AccordionBody>
                <HistoryChart datadump={gpHistoryTable} />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default GPScoreDetails;
