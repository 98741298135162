import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  numbersOfInspections,
  inspectionFilingStatus,
  monthlySummaryTable,
  testLiveData
} from 'data/dashboard/inspection-compliance';

import Greetings from './Greetings';
import NumberOfInspections from './number-of-inspections/NumberOfInspections';

import Location from '../utils/Location';
import NotInspected from './not-inspected/NotInspected';
import InspectionFilingStatus from './inspection-filing-status/InspectionFilingStatus';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Accordion from 'react-bootstrap/Accordion';
import CurrentMonthDetails from './CurrentMonthDetails';
import DetailsTable from '../utils/DailyDetails';
import { useLocation } from 'react-router-dom';

const InspectionCompliance = () => {
  const location = useLocation();
  const state = location.state;
  //console.log({ state });
  const indicator =
    state !== null && state.district === 'Adilabad' ? 'Adilabad' : 'All';
  const currentData = testLiveData[indicator];
  return (
    <>
      <Location stateParm={state} />

      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <Row className="g-0 h-100">
            <Col xs={12} className="mb-3">
              <Greetings />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3 mb-4">
        <Col xxl={8}>
          <NumberOfInspections data={numbersOfInspections} />
        </Col>
      </Row>
      <Row className="g-3 mb-4">
        <Col lg={6} xxl={5}>
          <NotInspected />
        </Col>
        <Col lg={6} xxl={5}>
          <Card className="h-md-100">
            <Card.Header className="py-2 bg-light">
              <h6 className="mb-0">Inspection Filing Rate</h6>
            </Card.Header>
            <Card.Body alignItems="between">
              <InspectionFilingStatus datadump={inspectionFilingStatus} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Detail Monthly Report (Oct-2023)
              </Accordion.Header>
              <AccordionBody>
                <CurrentMonthDetails data={monthlySummaryTable} />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
      <Row className="g-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Detail Monthly Report (Oct-2023)
              </Accordion.Header>
              <AccordionBody>
                <DetailsTable
                  data={currentData.details}
                  heading={currentData.heading}
                />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default InspectionCompliance;
