import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = datadump => ({
  legend: {
    left: 'center',
    bottom: 3,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 10,
      fontFamily: 'Poppins',
      fontWeight: '500'
    },
    itemGap: 16
  },
  dataset: {
    source: datadump
  },
  responsive: true,
  xAxis: { type: 'category', boundaryGap: true },
  yAxis: [
    {
      type: 'value',
      name: '%',
      position: 'left',
      min: 50,
      max: 100,
      boundaryGap: true
    },
    {
      type: 'value',
      name: 'No of days',
      position: 'right',
      min: 5,
      max: 30,
      boundaryGap: true
    }
  ],
  series: [
    {
      type: 'line',
      edgeDistance: '5%',
      yAxisIndex: 0,
      label: {
        show: false,
        fontSize: 8,
        borderType: 'none'
      },
      center: ['50%', '45%'],
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      }
    },
    {
      type: 'bar',
      edgeDistance: '5%',
      barWidth: '20%',
      yAxisIndex: 1,
      label: {
        show: false,
        fontSize: 8,
        borderType: 'none'
      },
      center: ['50%', '45%'],
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      }
    },
    {
      type: 'line',
      edgeDistance: '5%',
      yAxisIndex: 0,
      label: {
        show: false,
        fontSize: 8,
        borderType: 'none'
      },
      center: ['50%', '45%'],
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      }
    },
    {
      type: 'line',
      edgeDistance: '5%',
      yAxisIndex: 0,
      label: {
        show: false,
        fontSize: 8,
        borderType: 'none'
      },
      center: ['50%', '45%'],
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      }
    }
  ],
  color: [
    getColor('green'),
    getColor('primary'),
    getColor('yellow'),
    getColor('red'),
    rgbaColor(getColor('red'), 0.5)
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const HistoryChart = ({ datadump }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(datadump)}
      style={{ height: '21rem' }}
      responsive="true"
    />
  );
};

HistoryChart.propTypes = {
  datadump: PropTypes.array.isRequired
};
export default HistoryChart;
