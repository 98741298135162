import React from 'react';
import { Row, Card } from 'react-bootstrap';
import { testLiveData } from 'data/dashboard/gpdata';
import Location from '../utils/Location';
import DetailsTable from '../utils/DailyDetails';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from 'react-router-dom';
import Greetings from '../month-till-date/Greetings';

import LiveStats from './LiveStats';

const Live = () => {
  const location = useLocation();
  const state = location.state;
  //console.log({ state });
  const indicator =
    state !== null && state.district === 'Adilabad' ? 'Adilabad' : 'All';
  const currentData = testLiveData[indicator];

  return (
    <>
      <Location stateParm={state} />

      <Row className="g-3 mb-3 fs--1 fs-semi-bold">
        <Greetings heading="Live Today" />
      </Row>
      <LiveStats data={currentData.data} prevText="yesterday" />
      <Row className="g-3 mb-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Details</Accordion.Header>
              <AccordionBody>
                <DetailsTable
                  data={currentData.details}
                  heading={currentData.heading}
                />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default Live;
