import React, { useRef } from 'react';
import classNames from 'classnames';
import { Badge, Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberOfInspectionsChart from './NumberOfInspectionsChart';

const FormCheck = ({ title, id, inputClass, checkBoxClass, handleLegend }) => {
  return (
    <Form.Check
      className={classNames(checkBoxClass, 'd-flex align-items-center mb-0')}
    >
      <Form.Check.Input
        onChange={event => handleLegend(event, title)}
        type="checkbox"
        id={id}
        defaultChecked
        className={classNames(
          inputClass,
          'dot mt-0 shadow-none remove-checked-icon rounded-circle cursor-pointer'
        )}
      />
      <Form.Check.Label
        htmlFor={id}
        className="form-check-label lh-base mb-0 fs--2 text-500 fw-semi-bold font-base cursor-pointer"
      >
        {title}
      </Form.Check.Label>
    </Form.Check>
  );
};

FormCheck.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  checkBoxClass: PropTypes.string,
  handleLegend: PropTypes.func
};

const InspectionsCategory = ({
  title,
  number,
  percentage,
  icon,
  badgeColor
}) => {
  return (
    <div>
      <h6 className="fs-0 d-flex align-items-center text-700 mb-1">
        {number}
        <Badge pill bg="transparent" className={classNames(badgeColor, 'px-0')}>
          <FontAwesomeIcon icon={icon} className="fs--2 ms-2 me-1" />
          {percentage}
        </Badge>
      </h6>
      <h6 className="fs--2 text-600 mb-0 text-nowrap">{title}</h6>
    </div>
  );
};

InspectionsCategory.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeColor: PropTypes.string
};

const NumberOfInspections = ({ data }) => {
  const chartRef = useRef(null);
  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name: name
    });
  };
  return (
    <Card className="h-100">
      <Card.Header className="d-md-flex justify-content-between border-bottom border-200 py-3 py-md-2">
        <h6 className="mb-2 mb-md-0 py-md-2">Number of Inspections</h6>
        <Row className="g-md-0">
          <Col xs="auto" className="d-md-flex">
            <FormCheck
              title="Target Vs Actual"
              id="targetInspection"
              inputClass="ceo-inspection"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
            <FormCheck
              title="Scheduled Vs Actual"
              id="scheduledInspection"
              inputClass="dpo-inspection"
              checkBoxClass="mt-n1 mt-md-0 me-md-3"
              handleLegend={handleLegend}
            />
          </Col>
          <Col xs="auto" className="d-md-flex">
            <FormCheck
              title="Filling Rate"
              id="fillingInspection"
              inputClass="mpo-inspection"
              checkBoxClass="me-md-3"
              handleLegend={handleLegend}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="scrollbar overflow-y-hidden">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <InspectionsCategory
              title="CEO Inspections"
              number="125"
              percentage="5.3%"
              icon="caret-up"
              badgeColor="text-success"
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <div className="d-flex align-items-center">
            <InspectionsCategory
              title="Dy. CEO Inspections"
              number="100"
              percentage="3.20%"
              icon="caret-up"
              badgeColor="text-primary"
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <div className="d-flex align-items-center">
            <InspectionsCategory
              title="DPO Inspections"
              number="53"
              percentage="2.3%"
              icon="caret-down"
              badgeColor="text-warning"
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <div className="d-flex align-items-center">
            <InspectionsCategory
              title="DLPO Inspections"
              number="53"
              percentage="2.3%"
              icon="caret-down"
              badgeColor="text-primary"
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <div className="d-flex align-items-center">
            <InspectionsCategory
              title="MPDO Inspections"
              number="53"
              percentage="2.3%"
              icon="caret-down"
              badgeColor="text-warning"
            />
            <div
              className="bg-200 mx-3"
              style={{ height: '24px', width: '1px' }}
            ></div>
          </div>
          <InspectionsCategory
            title="MPO Inspections"
            number="136"
            percentage="3.12%"
            icon="caret-up"
            badgeColor="text-danger"
          />
        </div>
        <NumberOfInspectionsChart data={data} ref={chartRef} />
      </Card.Body>
    </Card>
  );
};

NumberOfInspections.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default NumberOfInspections;
