import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { upOrDown } from './pageutils';
import GaugeComponent from 'react-gauge-component';
import { Link } from 'react-router-dom';

const ScoreWidget = ({
  heading,
  dataDump,
  level = 'State',
  link = '#',
  curState
}) => {
  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <div className="pe-2">
            <p className="mt-1 mb-2 fs-2 fs-bold">{heading}</p>
          </div>
        </Row>
        <Row>
          <div>
            <Link to={link} state={curState}>
              <GaugeComponent
                minValue={0}
                maxValue={100}
                value={dataDump.currentScore}
                pointer={{
                  elastic: true,
                  animationDelay: 1000
                }}
                labels={{
                  tickLabels: {
                    ticks: [
                      { value: 20 },
                      { value: 40 },
                      { value: 60 },
                      { value: 80 },
                      { value: 100 }
                    ]
                  }
                }}
                arc={{
                  colorArray: [getColor('red'), getColor('green')],
                  subArcs: [{ limit: 20 }, { limit: 40 }, {}, {}, {}]
                }}
              />
            </Link>
          </div>

          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                Change from last month
              </Tooltip>
            }
          >
            <div className="text-center fs-1 mb-3">
              {dataDump.improvement}
              <span>{upOrDown(dataDump.improvement)}</span>
            </div>
          </OverlayTrigger>
          <div className="mt-3" />
          <Col>
            <Row className="text-center fs--2">Best in Class </Row>
            <Row className="fw-bold text-center fs--2">
              {' '}
              {dataDump.bestInClass.district}
            </Row>
            <Row className="fw-semi-bold text-center fs--2">
              {dataDump.bestInClass.score}
            </Row>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                  Rank in {level}
                </Tooltip>
              }
            >
              <div className="bg-soft-primary py-3 text-center">
                <h5 className="text-primary mb-1 d-flex flex-column mt-n1 fs-2 fs-bold">
                  <span>{dataDump.rank}</span>
                </h5>
                <div className="text-primary fs--2">Rank in {level}</div>
              </div>
            </OverlayTrigger>
          </Col>
          <Col>
            <Row className="justify-content-end fs--2"> State Average</Row>
            <Row className="justify-content-end fw-semi-bold text-center fs--2">
              {dataDump.mostImproved.score}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ScoreWidget.propTypes = {
  heading: PropTypes.string.isRequired,
  dataDump: PropTypes.any.isRequired,
  link: PropTypes.string,
  level: PropTypes.string,
  curState: PropTypes.any
};

export default ScoreWidget;
