import React from 'react';
import PropTypes from 'prop-types';

const HBoardItemRow = ({ sNo, rowItem, category }) => {
  return (
    <>
      <tr className={category}>
        <td className="white-space-nowrap">{sNo}</td>
        {rowItem.map((cellItem, index) => (
          <td key={index} className="white-space-nowrap">
            {cellItem}
          </td>
        ))}
      </tr>
    </>
  );
};

const HBoards = ({ top5Data, bottom5Data }) => {
  return (
    <>
      {top5Data.map((itDetails, index) => (
        <HBoardItemRow
          sNo={index + 1}
          rowItem={itDetails}
          key={index}
          category="text-900 bg-soft-success"
        />
      ))}
      {bottom5Data.map((itDetails, index) => (
        <HBoardItemRow
          sNo={33 - 4 + index}
          rowItem={itDetails}
          key={index}
          category="text-900 bg-soft-danger"
        />
      ))}
    </>
  );
};

HBoardItemRow.propTypes = {
  sNo: PropTypes.number.isRequired,
  rowItem: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired
};

HBoards.propTypes = {
  top5Data: PropTypes.array.isRequired,
  bottom5Data: PropTypes.array.isRequired
};

export default HBoards;
