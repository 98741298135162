import React from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { getColumns } from './pageutils';

const DetailsTable = ({ data, heading }) => {
  return (
    <AdvanceTableWrapper
      columns={getColumns(heading)}
      data={data}
      sortable
      filterable
    >
      <div className="mx-ncard mt-3">
        <AdvanceTable
          table
          headerClassName="bg-soft-primary text-800 text-nowrap align-middle font-sans-serif"
          rowClassName="align-middle white-space-nowrap fw-semi-bold"
          tableProps={{
            className: 'fs--1 mb-0',
            striped: true
          }}
        />
      </div>
    </AdvanceTableWrapper>
  );
};

DetailsTable.propTypes = {
  data: PropTypes.array.isRequired,
  heading: PropTypes.array.isRequired
};

export default DetailsTable;
