import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = datadump => ({
  legend: {
    left: 'center',
    bottom: 3,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 10,
      fontFamily: 'Poppins',
      fontWeight: '500'
    },
    itemGap: 16
  },
  dataset: {
    source: datadump
  },
  series: [
    {
      type: 'pie',
      radius: ['70%'],
      edgeDistance: '5%',
      label: {
        show: false,
        fontSize: 8,
        position: 'inner'
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: getColor('gray-100'),
        label: {
          show: true,
          position: 'inner',
          fontSize: 8,
          formatter: function (params) {
            return params.Value + '%\n';
          }
        },
        labelLine: {
          show: false
        }
      },
      center: ['50%', '45%'],
      color: [
        rgbaColor(getColor('red'), 0.5),
        rgbaColor(getColor('green'), 0.5)
      ]
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const InspectionFilingStatus = ({ datadump }) => {
  return <ReactEChartsCore echarts={echarts} option={getOption(datadump)} />;
};

InspectionFilingStatus.propTypes = {
  datadump: PropTypes.array.isRequired
};
export default InspectionFilingStatus;
