export const numbersOfInspections = [
  [
    { value: 45, target_value: 145, achived_value: 45 },
    { value: 35, target_value: 135, achived_value: 55 },
    { value: 55, target_value: 155, achived_value: 55 },
    { value: 55, target_value: 155, achived_value: 55 },
    { value: 45, target_value: 145, achived_value: 45 },
    { value: 30, target_value: 135, achived_value: 35 }
  ],
  [
    { value: 58, target_value: 158, achived_value: 45 },
    { value: 42, target_value: 142, achived_value: 55 },
    { value: 65, target_value: 165, achived_value: 65 },
    { value: 65, target_value: 165, achived_value: 55 },
    { value: 65, target_value: 165, achived_value: 65 },
    { value: 30, target_value: 130, achived_value: 30 }
  ],
  [
    { value: 38, target_value: 145, achived_value: 45 },
    { value: 25, target_value: 125, achived_value: 25 },
    { value: 42, target_value: 175, achived_value: 65 },
    { value: 49, target_value: 155, achived_value: 155 },
    { value: 55, target_value: 145, achived_value: 45 },
    { value: 90, target_value: 135, achived_value: 125 }
  ]
];

export const monthlySummaryTable = [
  {
    name: 'Adilabad',
    target_val: 10,
    achived_val: 1,
    target_achived_per: 3,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Bhadradri Kothagudem',
    target_val: 50,
    achived_val: 12,
    target_achived_per: 23,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 52,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Hyderabad',
    target_val: 11,
    achived_val: 5,
    target_achived_per: 53,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 72,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 45,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jagitial',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jangaon',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jayashankar Bhupalpally',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jogulamba Gadwal',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Kamareddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Karimnagar',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Khammam',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Komaram Bheem',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mahabubabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mahabubnagar',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mancherial',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Medak',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Medchal Malkajgiri',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mulugu',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nagarkurnool',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Narayanpet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nalgonda',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nirmal',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nizamabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Peddapalli',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Rajanna Sircilla',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Ranga Reddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Sangareddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Siddipet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Suryapet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Vikarabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Wanaparthy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Warangal Rural',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Warangal Urban',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Yadadri Bhuvanagiri',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  }
];

export const scores = {
  fillingRate: {
    currentScore: 83.6,
    improvement: 2.2,
    rank: 11,
    bestInClass: { district: 'Narayanpet', score: '92.8' },
    mostImproved: { district: 'Asifabad', score: '3.4' }
  }
};

export const lmsStatistics = [
  {
    id: 0,
    title: 'highest inspection completed by Karimnagar',
    amount: 450,
    amountLastMonth: 670,
    icon: 'user-graduate',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 1,
    title: 'minimum inspection completed by Bhupalpally',
    amount: 2,
    amountLastMonth: 157,
    icon: 'chalkboard-teacher',
    color: 'info',
    className:
      'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
  }
];
