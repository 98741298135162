import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  numbersOfInspections,
  monthlySummaryTable,
  scores,
  lmsStatistics
} from 'data/dashboard/month-till-date';
import Greetings from './Greetings';
import NumberOfInspections from './number-of-inspections/NumberOfInspections';

import Location from '../utils/Location';

import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import Accordion from 'react-bootstrap/Accordion';
import CurrentMonthDetails from './CurrentMonthDetails';
import ScoreWidget from '../utils/scoreWidget';
import PieEdgeAlignChart from './PieEdgeAlignChart';
import LmsStats from './lms-stats/LmsStats';

const MonthTillDate = () => {
  const state = {
    district: 'Karimnagar'
  };
  return (
    <>
      <Row className="g-3 mb-3">
        <Location stateParm={state} />
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <Row className="g-0 h-100">
            <Col xs={12} className="mb-3">
              <Greetings />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3 mb-4">
        <Col lg={12}>
          <NumberOfInspections data={numbersOfInspections} />
        </Col>
      </Row>
      <Row className="g-3 mb-4 justify-content-center">
        <Col lg={6}>
          <ScoreWidget dataDump={scores.fillingRate} heading="Filling Rate" />
        </Col>
        <Col lg={6}>
          <PieEdgeAlignChart />
        </Col>
      </Row>
      <Row className="g-3 mb-4">
        <Col lg={12}>
          <LmsStats data={lmsStatistics} />
        </Col>
      </Row>
      <Row className="g-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Detail Report</Accordion.Header>
              <AccordionBody>
                <CurrentMonthDetails data={monthlySummaryTable} />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default MonthTillDate;
