import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

var level = 0;
export function upOrDown(param) {
  return param > 0 ? (
    <FontAwesomeIcon
      icon={['fa', 'arrow-up']}
      transform="shrink-1"
      className="ms-1 text-success"
    />
  ) : (
    <FontAwesomeIcon
      icon={['fa', 'arrow-down']}
      transform="shrink-1"
      className="ms-1 text-danger"
    />
  );
}

function foo(rowData) {
  const [curState, hLevel] = UseCurrentState(
    rowData.data[rowData.row.index][0]
  );
  return hLevel ? (
    <>
      <Link to="#!" state={curState}>
        {rowData.data[rowData.row.index][0]}
      </Link>
    </>
  ) : (
    <>{rowData.data[rowData.row.index][0]}</>
  );
}

function UseCurrentState(obj) {
  const location = useLocation();
  let state = Object.assign({}, location.state);
  let levels = ['district', 'mandal', 'panchayat'];
  var highestLevel = false;
  if (state === undefined || state === null) {
    state = { district: obj };
    highestLevel = true;
  }
  levels.forEach(item => {
    if (!highestLevel) {
      if (state[[item]] === undefined || state[[item]] === 'All') {
        highestLevel = true;
        state[[item]] = obj;
      }
    }
  });
  return [state, highestLevel];
}

export function getColumns(hArray) {
  let finalColumns = [];
  finalColumns = hArray.map((hItem, index) => {
    let columnDetail = {
      accessor: { index }.index.toString(),
      Header: { hItem }.hItem,
      cellProps: { className: 'text-center' },
      headerProps: { className: 'text-center' }
    };
    if (index === 0) {
      columnDetail.cellProps = {
        className: 'py-2 pe-4'
      };
      columnDetail.headerProps = { style: { minWidth: '14.625rem' } };
      columnDetail.Cell = foo;
    }
    return columnDetail;
  });
  return finalColumns;
}

export function getLevel(param) {
  const values = ['district', 'madal', 'panchayat'];
  if (param.district !== undefined && param.district !== 'All') level = 1;
  if (param.mandal !== undefined && param.mandal !== 'All') level = 2;
  if (param.panchayat !== undefined && param.panchayat !== 'All') level = 3;
  return [level, values[level]];
}
export const useDate = () => {
  const locale = 'en';
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: 'long' });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: 'long'
  })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'
  }, `;

  const time = today.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric'
  });

  return {
    date,
    time,
    wish
  };
};
