import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { style: { minWidth: '14.625rem' } },
    cellProps: {
      className: 'py-2 pe-4'
    }
  },
  {
    accessor: 'ceo_target_achived_per',
    Header: 'CEO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'ceo_scheduled_achived_per',
    Header: 'CEO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'ceo_fill_rate_per',
    Header: 'CEO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dyceo_target_achived_per',
    Header: 'Dy. CEO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dyceo_scheduled_achived_per',
    Header: 'Dy. CEO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dyceo_fill_rate_per',
    Header: 'Dy. CEO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dpo_target_achived_per',
    Header: 'DPO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dpo_scheduled_achived_per',
    Header: 'DPO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dpo_fill_rate_per',
    Header: 'DPO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dlpo_target_achived_per',
    Header: 'DLPO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dlpo_scheduled_achived_per',
    Header: 'DLPO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'dlpo_fill_rate_per',
    Header: 'DLPO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpdo_target_achived_per',
    Header: 'MPDO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpdo_scheduled_achived_per',
    Header: 'MPDO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpdo_fill_rate_per',
    Header: 'MPDO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpo_target_achived_per',
    Header: 'MPO Target Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { target_achived_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Achived/Target: {achived_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{target_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpo_scheduled_achived_per',
    Header: 'MPO Scheduled Vs Actual %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { scheduled_achived_per } = rowData.row.original;
      const { scheduled_val } = rowData.row.original;
      const { scheduled_achived_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Scheduled Achived/Target: {scheduled_achived_val}/{scheduled_val}
            </Tooltip>
          }
        >
          <span>{scheduled_achived_per}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    accessor: 'mpo_fill_rate_per',
    Header: 'MPO Filling Rate %',
    cellProps: { className: 'text-center' },
    headerProps: { className: 'text-center' },
    Cell: rowData => {
      const { fill_rate_per } = rowData.row.original;
      const { target_val } = rowData.row.original;
      const { fill_rate_target_val } = rowData.row.original;
      return (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
              Filling Rate Achived/Target: {fill_rate_target_val}/{target_val}
            </Tooltip>
          }
        >
          <span>{fill_rate_per}</span>
        </OverlayTrigger>
      );
    }
  }
];

const CurrentMonthDetails = ({ data }) => {
  return (
    <AdvanceTableWrapper columns={columns} data={data} sortable filterable>
      <div className="mx-ncard mt-3">
        <AdvanceTable
          table
          headerClassName="bg-soft-primary text-800 text-nowrap align-middle font-sans-serif"
          rowClassName="align-middle white-space-nowrap fw-semi-bold"
          tableProps={{
            className: 'fs--1 mb-0',
            striped: true
          }}
        />
      </div>
    </AdvanceTableWrapper>
  );
};

CurrentMonthDetails.propTypes = {
  data: PropTypes.array.isRequired
};

export default CurrentMonthDetails;
