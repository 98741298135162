import React, { useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CountUp from 'react-countup';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import NotInspectedChart from './NotInspectedChart';

const NotInspected = () => {
  const [stats] = useState([
    {
      title: 'In last 80 days',
      value: 79,
      color: 'info',
      valcount: 11,
      totalcount: 15
    },
    {
      title: 'In last 90 days',
      value: 85,
      color: 'primary',
      valcount: 65,
      totalcount: 57
    }
  ]);

  return (
    <Card className="h-100">
      <FalconCardHeader
        light
        title="GPs Not Inspected"
        titleTag="h6"
        className="py-2"
      />
      <Card.Body>
        <Row className="align-items-center h-100">
          <Col sm={4} className="order-1 order-sm-0">
            {stats.map((stat, index) => (
              <div
                key={stat.title.toLowerCase().split(' ').join('-')}
                className={`${
                  index !== 2 && 'pb-3 mb-3 border-bottom border-200'
                }`}
              >
                <div className="position-relative ps-3">
                  <div
                    className={`position-absolute h-100 start-0 rounded bg-${stat.color}`}
                    style={{ width: '4px' }}
                  />
                  <h6 className="fs--2 text-600 mb-1">{stat.title}</h6>
                  <Flex alignItems="center">
                    <CountUp
                      start={0}
                      end={stat.value}
                      duration={2.75}
                      className="text-700 fs-2 me-2"
                      suffix="%"
                      separator=","
                    />
                  </Flex>
                  <h6 className="fs--3 text-600 mb-1">
                    Count/Total: {stat.valcount}/{stat.totalcount}
                  </h6>
                </div>
              </div>
            ))}
          </Col>
          <Col sm={8}>
            <NotInspectedChart />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NotInspected;
