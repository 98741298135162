import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import LmsStatItem from './LiveStatItem';

const LiveStats = ({ data, prevText }) => {
  return (
    <Card className="mb-3">
      <Card.Body className="px-xxl-0 pt-4">
        <Row className="g-0">
          {data.map(stat => (
            <LmsStatItem key={stat.id} stat={stat} prev={prevText} />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

LiveStats.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  prevText: PropTypes.string.isRequired
};

export default LiveStats;
