export const scores = {
  psScore: {
    currentScore: 83.6,
    improvement: 2.2,
    rank: 11,
    bestInClass: { district: 'Narayanpet', score: '92.8' },
    mostImproved: { district: 'Asifabad', score: '3.4' }
  },
  dsrScore: {
    currentScore: 69.2,
    improvement: 0.9,
    rank: 3,
    bestInClass: { district: 'Ranga Reddy', score: '71.6' },
    mostImproved: { district: 'Khammam', score: '2.1' }
  },
  gpScore: {
    currentScore: 51.9,
    improvement: -1.2,
    rank: 29,
    bestInClass: { district: 'Siddipet', score: '79' },
    mostImproved: { district: 'Nagarkurnool', score: '6.2' }
  }
};

export const attendanceSummary = [
  ['Category', 'Value'],
  ['Working', 3303],
  ['Trainings', 1016],
  ['Leave', 381],
  ['Not Recorded', 312]
];

export const atttendanceDistribution = [
  ['Category', 'Value'],
  ['Before 7 AM', 5],
  ['7-9 AM', 2],
  ['9-11 AM', 15],
  ['After 11', 7]
];

export const daysPresent = [
  ['Days Present', 'Value'],
  ['>18', 51],
  ['11-18', 120],
  ['6-10s', 41],
  ['<=5 ', 9]
];

export const DSRFilingStatus = [
  [23, '>18'],
  [71, '11-18'],
  [33, '6-10'],
  [14, '<=5 ']
];

export const statusData = [
  {
    id: 0,
    title: 'Not Reported',
    value: 73,
    valueYesterday: 65.2,
    icon: 'user',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 1,
    title: 'Late Comer',
    value: 5.2,
    valueYesterday: 7.6,
    icon: 'clock',
    color: 'success',
    className:
      'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
  },
  {
    id: 2,
    title: 'Absent for 3 days',
    value: 8.1,
    valueYesterday: 6.4,
    icon: 'flag',
    color: 'info',
    className:
      'border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0'
  },
  {
    id: 3,
    title: 'DSR Filed',
    value: 81,
    valueYesterday: 75,
    icon: 'list',
    color: 'warning',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  }
];

export const dayWiseAttendance = [
  ['Date', 'Working (on Duty)', 'Training', 'Leave', 'Not Reported'],
  ['1-Sep-23', 152, 48, 11, 20],
  ['2-Sep-23', 69, 132, 12, 18],
  ['3-Sep-23', 9, 0, 0, 222],
  ['4-Sep-23', 218, 10, 0, 3],
  ['5-Sep-23', 49, 14, 43, 125],
  ['6-Sep-23', 197, 32, 1, 1],
  ['7-Sep-23', 151, 26, 37, 17],
  ['8-Sep-23', 109, 2, 15, 105],
  ['9-Sep-23', 15, 0, 0, 216],
  ['10-Sep-23', 53, 0, 0, 178],
  ['11-Sep-23', 78, 72, 36, 45],
  ['12-Sep-23', 145, 21, 14, 51],
  ['13-Sep-23', 157, 45, 21, 8],
  ['14-Sep-23', 109, 37, 25, 60],
  ['15-Sep-23', 198, 30, 2, 1],
  ['16-Sep-23', 68, 65, 26, 72],
  ['17-Sep-23', 10, 0, 0, 221],
  ['18-Sep-23', 129, 93, 1, 8],
  ['19-Sep-23', 152, 48, 11, 20],
  ['20-Sep-23', 50, 87, 87, 7],
  ['21-Sep-23', 99, 79, 34, 19],
  ['22-Sep-23', 157, 6, 11, 57],
  ['23-Sep-23', 106, 67, 42, 16],
  ['24-Sep-23', 13, 0, 0, 218],
  ['25-Sep-23', 193, 11, 26, 1],
  ['26-Sep-23', 173, 3, 0, 55],
  ['27-Sep-23', 49, 109, 32, 41],
  ['28-Sep-23', 212, 3, 6, 10],
  ['29-Sep-23', 68, 66, 95, 2],
  ['30-Sep-23', 91, 59, 47, 34]
];

export const monthlySummaryHeading = [
  'Name',
  'Attendance %',
  'No  of Days Present',
  'Days DSR filed'
];

export const monthlyGPScoreHeading = [
  'Name',
  'MAS Compliance',
  'GP Score',
  'Deviation <25%'
];

export const monthlySummaryTable = [
  ['Adilabad', 81.2, 14.2, 16.1],
  ['Bhadradri Kothagudem', 71.6, 18.1, 15.2],
  ['Hyderabad', 45.2, 10.2, 9.2],
  ['Jagitial', 69.2, 13.1, 15.5],
  ['Jangaon', 54.1, 11.5, 12.9],
  ['Jayashankar Bhupalpally', 90.2, 17.2, 9.3],
  ['Jogulamba Gadwal', 81.7, 16.6, 19.3],
  ['Kamareddy', 75.6, 14.7, 14.9],
  ['Karimnagar', 83.6, 18.1, 12.1],
  ['Khammam', 74.2, 17.4, 12.0],
  ['Komaram Bheem', 62, 15.8, 9.3],
  ['Mahabubabad', 54.3, 14.1, 7.9],
  ['Mahabubnagar', 85.6, 19.1, 18.6],
  ['Mancherial', 90.1, 18.4, 15.3],
  ['Medak', 45.2, 11.1, 9.1],
  ['Medchal Malkajgiri', 72.1, 17.6, 7.9],
  ['Mulugu', 56.2, 14.0, 7.8],
  ['Nagarkurnool', 90.1, 18.7, 17.3],
  ['Narayanpet', 92.8, 19.4, 12.9],
  ['Nalgonda', 72.6, 15.3, 18.7],
  ['Nirmal', 55.9, 12.9, 8.1],
  ['Nizamabad', 45.2, 15.1, 14.9],
  ['Peddapalli', 87.1, 18.3, 18.8],
  ['Rajanna Sircilla', 65.2, 15.0, 13.1],
  ['Ranga Reddy', 90.1, 18.3, 15.9],
  ['Sangareddy', 72.5, 15.9, 12.4],
  ['Siddipet', 84.6, 16.7, 14.7],
  ['Suryapet', 82.9, 17.2, 19.1],
  ['Vikarabad', 74.1, 14.3, 10.7],
  ['Wanaparthy', 65.3, 13.9, 16.7],
  ['Warangal Rural', 61.2, 14.7, 10.5],
  ['Warangal Urban/', 70.1, 17.1, 14.0],
  ['Yadadri Bhuvanagiri', 45.2, 12.9, 12.3]
];
export const monthlyGPSummaryTable = [
  ['Adilabad', 81.2, 52.1, 16.1],
  ['Bhadradri Kothagudem', 71.6, 78.1, 15.2],
  ['Hyderabad', 45.2, 80.2, 9.2],
  ['Jagitial', 69.2, 63.1, 15.5],
  ['Jangaon', 54.1, 71.5, 12.9],
  ['Jayashankar Bhupalpally', 90.2, 87.2, 9.3],
  ['Jogulamba Gadwal', 81.7, 76.6, 19.3],
  ['Kamareddy', 75.6, 64.7, 14.9],
  ['Karimnagar', 83.6, 58.1, 12.1],
  ['Khammam', 74.2, 77.4, 12.0],
  ['Komaram Bheem', 62, 65.8, 9.3],
  ['Mahabubabad', 54.3, 34.1, 7.9],
  ['Mahabubnagar', 85.6, 69.1, 18.6],
  ['Mancherial', 90.1, 88.4, 15.3],
  ['Medak', 45.2, 81.1, 9.1],
  ['Medchal Malkajgiri', 72.1, 77.6, 7.9],
  ['Mulugu', 56.2, 84.0, 7.8],
  ['Nagarkurnool', 90.1, 78.7, 17.3],
  ['Narayanpet', 92.8, 89.4, 12.9],
  ['Nalgonda', 72.6, 65.3, 18.7],
  ['Nirmal', 55.9, 72.9, 8.1],
  ['Nizamabad', 45.2, 55.1, 14.9],
  ['Peddapalli', 87.1, 68.3, 18.8],
  ['Rajanna Sircilla', 65.2, 75.0, 13.1],
  ['Ranga Reddy', 90.1, 88.3, 15.9],
  ['Sangareddy', 72.5, 65.9, 12.4],
  ['Siddipet', 84.6, 76.7, 14.7],
  ['Suryapet', 82.9, 87.2, 19.1],
  ['Vikarabad', 74.1, 74.3, 10.7],
  ['Wanaparthy', 65.3, 63.9, 16.7],
  ['Warangal Rural', 61.2, 54.7, 10.5],
  ['Warangal Urban/', 70.1, 77.1, 14.0],
  ['Yadadri Bhuvanagiri', 45.2, 82.9, 12.3]
];

export const historyTable = [
  ['Month', 'Reporting', 'Days Present', 'Login before 11', 'DSR Compliance'],
  ['Jan', 72.6, 16.1, 82.1, 64.2],
  ['Feb', 73.1, 16.3, 80.3, 63.9],
  ['Mar', 74.3, 16.3, 79.2, 67.1],
  ['Apr', 78.6, 15.9, 82.3, 69.2],
  ['May', 80.1, 16.4, 81.8, 66.8],
  ['Jun', 79.2, 16.1, 78.2, 65.4],
  ['Jul', 76.8, 15.7, 76.1, 62.1],
  ['Aug', 79.4, 16.0, 80.8, 63.2]
];

export const gpHistoryTable = [
  [
    'Month',
    'GP Score',
    'No of working days',
    'MAS Compliance',
    'High Accuracy'
  ],
  ['Jan', 72.6, 16.1, 82.1, 64.2],
  ['Feb', 73.1, 16.3, 80.3, 63.9],
  ['Mar', 74.3, 16.3, 79.2, 67.1],
  ['Apr', 78.6, 15.9, 82.3, 69.2],
  ['May', 80.1, 16.4, 81.8, 66.8],
  ['Jun', 79.2, 16.1, 78.2, 65.4],
  ['Jul', 76.8, 15.7, 76.1, 62.1],
  ['Aug', 79.4, 16.0, 80.8, 63.2]
];
export const liveStatistics = [
  {
    id: 0,
    title: 'Not Reported',
    value: 51.2,
    valueYesterday: 21.3,
    icon: 'user',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 1,
    title: 'Late Comer',
    value: 0,
    valueYesterday: 10.1,
    icon: 'clock',
    color: 'success',
    className:
      'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
  },
  {
    id: 2,
    title: 'Absent last 3 days',
    value: 3.4,
    valueYesterday: 1.2,
    icon: 'flag',
    color: 'info',
    className:
      'border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0'
  },
  {
    id: 3,
    title: 'DSR Filed',
    value: 63,
    valueYesterday: 91,
    icon: 'list',
    color: 'warning',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  }
];

export const aLiveStatistics = [
  {
    id: 0,
    title: 'Not Reported',
    value: 72.1,
    valueYesterday: 9.2,
    icon: 'user',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 1,
    title: 'Late Comer',
    value: 10.7,
    valueYesterday: 8.2,
    icon: 'clock',
    color: 'success',
    className:
      'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
  },
  {
    id: 2,
    title: 'Absent last 3 days',
    value: 5.7,
    valueYesterday: 6.2,
    icon: 'flag',
    color: 'info',
    className:
      'border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0'
  },
  {
    id: 3,
    title: 'DSR Filed',
    value: 22,
    valueYesterday: 55,
    icon: 'list',
    color: 'warning',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  }
];
export const maxDays = 20;

export const dailyDetailsData = [
  ['Adilabad', 8.2, 0.2, 1.1, 55.2, 'district'],
  ['Bhadradri Kothagudem', 7.6, 1.1, 2.2, 75.2, 'district'],
  ['Hyderabad', 4.2, 0.2, 3.1, 19.2, 'district'],
  ['Jagitial', 9.2, 3.1, 0.2, 65.5, 'district'],
  ['Jangaon', 5.1, 0.5, 0.7, 42.9, 'district'],
  ['Jayashankar Bhupalpally', 9.2, 1.2, 0.6, 29.3, 'district'],
  ['Jogulamba Gadwal', 8.7, 1.6, 1.1, 69.3, 'district'],
  ['Kamareddy', 7.6, 1.7, 1.5, 45.9, 'district'],
  ['Karimnagar', 8.6, 1.1, 0.7, 82.1, 'district'],
  ['Khammam', 7.2, 0.4, 0.2, 62.0, 'district'],
  ['Komaram Bheem', 6.2, 0.8, 0.6, 45.3, 'district'],
  ['Mahabubabad', 5.3, 1.1, 0.4, 32.9, 'district'],
  ['Mahabubnagar', 8.6, 0.1, 2.9, 70.6, 'district'],
  ['Mancherial', 9.1, 1.4, 2.1, 75.3, 'district'],
  ['Medak', 4.2, 1.1, 0.3, 22.1, 'district'],
  ['Medchal Malkajgiri', 2.1, 1.6, 0.5, 47.9, 'district'],
  ['Mulugu', 5.2, 1.0, 2.2, 37.8, 'district'],
  ['Nagarkurnool', 9.1, 1.7, 0.6, 77.3, 'district'],
  ['Narayanpet', 2.8, 1.4, 0.8, 45.9, 'district'],
  ['Nalgonda', 7.6, 1.3, 3.1, 35.7, 'district'],
  ['Nirmal', 5.9, 1.9, 1.0, 18.1, 'district'],
  ['Nizamabad', 4.2, 1.1, 2.1, 40.9, 'district'],
  ['Peddapalli', 8.1, 1.3, 1.9, 81.8, 'district'],
  ['Rajanna Sircilla', 6.2, 1.0, 1.1, 43.1, 'district'],
  ['Ranga Reddy', 9.1, 0.3, 1.2, 65.9, 'district'],
  ['Sangareddy', 2.5, 0.9, 1.6, 53.4, 'district'],
  ['Siddipet', 8.6, 0.7, 0.1, 64.7, 'district'],
  ['Suryapet', 8.9, 0.2, 0.4, 69.1, 'district'],
  ['Vikarabad', 7.1, 0.3, 1.5, 53.7, 'district'],
  ['Wanaparthy', 6.3, 0.9, 0.3, 46.7, 'district'],
  ['Warangal Rural', 6.2, 1.7, 0.2, 39.5, 'district'],
  ['Warangal Urban/', 7.1, 1.1, 1.7, 54.0, 'district'],
  ['Yadadri Bhuvanagiri', 4.2, 2.9, 0.1, 28.3, 'district']
];
export const aDailyDetailsData = [
  ['Adilabad Rural', 8.2, 0.2, 1.1, 55.2, 'mandal'],
  ['Bazarhatnoor', 7.6, 1.1, 2.2, 75.2, 'mandal'],
  ['Bela', 4.2, 0.2, 3.1, 19.2, 'mandal'],
  ['Bheempur', 9.2, 3.1, 0.2, 65.5, 'mandal'],
  ['Boath', 5.1, 0.5, 0.7, 42.9, 'mandal'],
  ['Gadiguda', 9.2, 1.2, 0.6, 29.3, 'mandal'],
  ['Gudihatnur', 8.7, 1.6, 1.1, 69.3, 'mandal'],
  ['Ichoda', 7.6, 1.7, 1.5, 45.9, 'mandal'],
  ['Indervelly', 8.6, 1.1, 0.7, 82.1, 'mandal'],
  ['Jainath', 7.2, 0.4, 0.2, 62.0, 'mandal'],
  ['Mavala', 6.2, 0.8, 0.6, 45.3, 'mandal'],
  ['Narnoor', 5.3, 1.1, 0.4, 32.9, 'mandal'],
  ['Neradigonda', 8.6, 0.1, 2.9, 70.6, 'mandal'],
  ['Sirikonda', 5.2, 1.0, 2.2, 37.8, 'mandal'],
  ['Talamadugu', 9.1, 1.4, 2.1, 75.3, 'mandal'],
  ['Tamsi', 4.2, 1.1, 0.3, 22.1, 'mandal'],
  ['Utnoor', 2.1, 1.6, 0.5, 47.9, 'mandal']
];

const liveHeadings = [
  'Name',
  'Not Reported %',
  'Late Comer%',
  'Absent for last 3 days %',
  'DSR Filed %'
];
export const testLiveData = {
  All: {
    data: liveStatistics,
    details: dailyDetailsData,
    heading: liveHeadings
  },
  Adilabad: {
    data: aLiveStatistics,
    details: aDailyDetailsData,
    heading: liveHeadings
  }
};

export const AccuracyScore = [
  ['Category', 'Value'],
  ['High Accuracy', 150],
  ['Low Accuracy', 41]
];

export const OutcomeCategories = [
  'Sanitation',
  'Water',
  'Street Lights',
  'Greenery',
  'Admin'
];

export const OutcomeWiseScores = [
  { value: [95.1, 90.2, 99.8, 89.4, 98.9], name: 'Best in Class' },
  { value: [79.3, 84.2, 96.1, 55.2, 89.1], name: 'State' },
  { value: [80.1, 59.2, 98.4, 75.2, 96.2], name: 'Karimnagar', areaStyle: {} }
];

export const monthlyGPScoreSummaryTable = [
  ['Adilabad', 81.2, 64.2, 52],
  ['Bhadradri Kothagudem', 71.6, 78.1, 31],
  ['Hyderabad', 45.2, 80.2, 22],
  ['Jagitial', 69.2, 43.1, 82],
  ['Jangaon', 54.1, 51.5, 71],
  ['Jayashankar Bhupalpally', 90.2, 67.2, 57],
  ['Jogulamba Gadwal', 81.7, 76.6, 61],
  ['Kamareddy', 75.6, 84.7, 87],
  ['Karimnagar', 83.6, 58.1, 28],
  ['Khammam', 74.2, 67.4, 43],
  ['Komaram Bheem', 62, 75.8, 72],
  ['Mahabubabad', 54.3, 84.1, 66],
  ['Mahabubnagar', 85.6, 49.1, 59],
  ['Mancherial', 90.1, 58.4, 40],
  ['Medak', 45.2, 61.1, 77],
  ['Medchal Malkajgiri', 72.1, 77.6, 37],
  ['Mulugu', 56.2, 84.0, 44],
  ['Nagarkurnool', 90.1, 48.7, 60],
  ['Narayanpet', 92.8, 59.4, 37],
  ['Nalgonda', 72.6, 65.3, 49],
  ['Nirmal', 55.9, 72.9, 33],
  ['Nizamabad', 45.2, 85.1, 56],
  ['Peddapalli', 87.1, 58.3, 59],
  ['Rajanna Sircilla', 65.2, 65.0, 75],
  ['Ranga Reddy', 90.1, 78.3, 81],
  ['Sangareddy', 72.5, 85.9, 65],
  ['Siddipet', 84.6, 56.7, 57],
  ['Suryapet', 82.9, 67.2, 41],
  ['Vikarabad', 74.1, 74.3, 27],
  ['Wanaparthy', 65.3, 83.9, 39],
  ['Warangal Rural', 61.2, 54.7, 55],
  ['Warangal Urban/', 70.1, 67.1, 80],
  ['Yadadri Bhuvanagiri', 45.2, 72.9, 56]
];

const psAttendanceDistData = {
  Top5: [
    ['Narayanpet', 92.8],
    ['Jayashankar Bhupalpally', 90.2],
    ['Nagarkurnool', 90.1],
    ['Mancherial', 90.1],
    ['Ranga Reddy', 90.1]
  ],
  Bottom5: [
    ['Jangaon', 54.1],
    ['Yadadri Bhuvanagiri', 45.2],
    ['Nizamabad', 45.2],
    ['Medak', 45.2],
    ['Hyderabad', 45.2]
  ]
};

const psAttendanceMandalData = {
  Top5: [
    ['Narayanpet', 'Maganur', 97.8],
    ['Jangaon', 'Narmetta', 97.2],
    ['Narayanpet', 'Maddur', 96.1],
    ['Ranga Reddy', 'Basheerabad', 95.1],
    ['Mancherial', ' 	Kotapally', 95.0]
  ],
  Bottom5: [
    ['Jayashankar Bhupalpally', 'Gudipahad', 29.1],
    ['Yadadri Bhuvanagiri', ' 	 	Valigonda', 27.3],
    ['Yadadri Bhuvanagiri', ' 	Rajapet', 25.3],
    ['Hyderabad', 'Saidabad', 25.2],
    ['Hyderabad', 'Asifnagar', 22.1]
  ]
};

const psAttendanceGPData = {
  Top5: [
    ['Narayanpet', 'Maganur', 'Some GP1', 100],
    ['Narayanpet', 'Maganur', 'Some GP12', 100],
    ['Mancherial', ' 	Kotapally', 'Some GP3', 100.0],
    ['Mancherial', ' 	Kotapally', 'Some GP4', 99.0],
    ['Mancherial', ' 	Kotapally', 'Some GP5', 98.0]
  ],
  Bottom5: [
    ['Jayashankar Bhupalpally', 'Gudipahad', 'Bad GP1', 15.1],
    ['Yadadri Bhuvanagiri', ' Valigonda', 'Bad GP2', 14.1],
    ['Hyderabad', 'Saidabad', 'Bad GP3', 13.2],
    ['Hyderabad', 'AsifNagar', 'Bad GP4', 12.0],
    ['Hyderabad', 'Asifnagar', 'Bad GP4', 8.1]
  ]
};

export const psAttendanceData = {
  district: psAttendanceDistData,
  mandal: psAttendanceMandalData,
  gp: psAttendanceGPData
};
