import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = datadump => ({
  title: {
    text: 'Accuracy Distribution'
  },
  legend: {
    left: 'center',
    bottom: 3,
    itemWidth: 12,
    itemHeight: 12,
    borderRadius: 0,
    icon: 'circle',
    inactiveColor: getColor('gray-400'),
    inactiveBorderColor: 'transparent',
    textStyle: {
      color: getColor('gray-600'),
      fontSize: 10,
      fontFamily: 'Poppins',
      fontWeight: '500'
    },
    itemGap: 16
  },
  dataset: {
    source: datadump
  },
  series: [
    {
      type: 'pie',
      radius: ['70%'],
      edgeDistance: '5%',
      label: {
        show: false,
        position: 'inside',
        fontSize: 12,
        borderType: 'none'
      },
      center: ['50%', '45%'],
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      color: [getColor('green'), getColor('red')]
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const AccuracyDistrib = ({ datadump }) => {
  return (
    <div className="mt-3">
      <ReactEChartsCore
        echarts={echarts}
        option={getOption(datadump)}
        className=" pe-2 fs--3"
      />
    </div>
  );
};

AccuracyDistrib.propTypes = {
  datadump: PropTypes.array.isRequired
};
export default AccuracyDistrib;
