import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (categories, datadump) => ({
  title: {
    text: 'Outcomes Performance'
  },
  legend: {
    bottom: 'bottom'
  },

  radar: {
    indicator: [
      { text: categories[0], min: 40, max: 100 },
      { text: categories[1], min: 40, max: 100 },
      { text: categories[2], min: 40, max: 100 },
      { text: categories[3], min: 40, max: 100 },
      { text: categories[4], min: 40, max: 100 }
    ],
    center: ['50%', '50%'],
    startAngle: 90,
    splitNumber: 6,
    axisName: {
      formatter: '{value}',
      fontSize: 10
    },
    axisLine: {
      lineStyle: {
        color: getColor('gray-500')
      }
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(211, 253, 250, 0.1)'
      }
    }
  },
  series: [
    {
      type: 'radar',
      emphasis: {
        lineStyle: {
          width: 4
        }
      },
      label: {
        show: 'true',
        fontSize: 8
      },

      data: datadump,
      color: [
        getColor('green'),
        getColor('red'),
        getColor('primary'),
        rgbaColor(getColor('green'), 0.5)
      ]
    }
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  }
});

const OutcomeScores = ({ categories, datadump }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(categories, datadump)}
      className="fs--3"
      style={{ height: '40rem' }}
      responsive="true"
    />
  );
};

OutcomeScores.propTypes = {
  datadump: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired
};
export default OutcomeScores;
