import holdTicket from 'assets/img/support-desk/hold-tickets.png';
import openTicket from 'assets/img/support-desk/open-tickets.png';
import dueTicket from 'assets/img/support-desk/due-tickets.png';
import unassigned from 'assets/img/support-desk/unassigned.png';

export const statusData = [
  {
    color: 'primary',
    img: holdTicket,
    percentage: '5.3%',
    count: '25',
    title: 'CEO',
    dataArray: [10, 40, 30, 35, 20, 40],
    chartColor: '#2c7be5',
    icon: 'caret-up',
    className:
      'border-200 border-md-200 border-bottom border-md-end pb-x1 pe-md-x1'
  },
  {
    color: 'success',
    img: openTicket,
    percentage: '3.20%',
    count: '05',
    title: 'Dy. CEO',
    dataArray: [10, 12, 16, 14, 20, 25],
    chartColor: '#00d27a',
    icon: 'caret-up',
    className: 'ps-md-x1 pb-x1 pt-x1 pt-md-0 border-bottom border-200'
  },
  {
    color: 'info',
    img: dueTicket,
    percentage: '2.3%',
    count: '02',
    title: 'DPO',
    dataArray: [15, 10, 15, 10, 12, 10],
    chartColor: '#27bcfd',
    icon: 'caret-down',
    className:
      'border-md-200 border-200 border-bottom border-md-bottom-0 border-md-end pt-x1 pe-md-x1 pb-x1 pb-md-0'
  },
  {
    color: 'warning',
    img: unassigned,
    percentage: '3.129%',
    count: '03',
    title: 'DLPO',
    dataArray: [10, 15, 12, 11, 14, 12],
    chartColor: '#f5803e',
    icon: 'caret-up',
    className: 'ps-md-x1 pt-x1'
  }
];

export const numbersOfInspections = [
  [
    { value: 45, target_value: 145, achived_value: 45 },
    { value: 35, target_value: 135, achived_value: 55 },
    { value: 55, target_value: 155, achived_value: 55 },
    { value: 55, target_value: 155, achived_value: 55 },
    { value: 45, target_value: 145, achived_value: 45 },
    { value: 30, target_value: 135, achived_value: 35 }
  ],
  [
    { value: 58, target_value: 158, achived_value: 45 },
    { value: 42, target_value: 142, achived_value: 55 },
    { value: 65, target_value: 165, achived_value: 65 },
    { value: 65, target_value: 165, achived_value: 55 },
    { value: 65, target_value: 165, achived_value: 65 },
    { value: 30, target_value: 130, achived_value: 30 }
  ],
  [
    { value: 38, target_value: 145, achived_value: 45 },
    { value: 25, target_value: 125, achived_value: 25 },
    { value: 42, target_value: 175, achived_value: 65 },
    { value: 49, target_value: 155, achived_value: 155 },
    { value: 55, target_value: 145, achived_value: 45 },
    { value: 90, target_value: 135, achived_value: 125 }
  ]
];

export const inspectionFilingStatus = [
  ['Inspection Type', 'Value'],
  ['Partial filled ', 14],
  ['Completed', 33]
];

export const monthlySummaryTable = [
  {
    name: 'Adilabad',
    target_val: 10,
    achived_val: 1,
    target_achived_per: 3,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Bhadradri Kothagudem',
    target_val: 50,
    achived_val: 12,
    target_achived_per: 23,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 52,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Hyderabad',
    target_val: 11,
    achived_val: 5,
    target_achived_per: 53,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 72,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 45,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jagitial',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jangaon',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jayashankar Bhupalpally',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Jogulamba Gadwal',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Kamareddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Karimnagar',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Khammam',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Komaram Bheem',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mahabubabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mahabubnagar',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mancherial',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Medak',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Medchal Malkajgiri',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Mulugu',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nagarkurnool',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Narayanpet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nalgonda',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nirmal',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Nizamabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Peddapalli',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Rajanna Sircilla',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Ranga Reddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Sangareddy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Siddipet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Suryapet',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Vikarabad',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Wanaparthy',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Warangal Rural',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Warangal Urban',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  },
  {
    name: 'Yadadri Bhuvanagiri',
    target_val: 10,
    achived_val: 2,
    target_achived_per: 35,
    scheduled_val: 10,
    scheduled_achived_val: 1,
    scheduled_achived_per: 22,
    fill_rate_target_val: 10,
    fill_rate_val: 1,
    fill_rate_per: 35,
    total_target_val: 10,
    total_achived_val: 1,
    total_per: 35
  }
];

export const dailyDetailsData = [
  [
    'Adilabad',
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    'district'
  ],
  [
    'Bhadradri Kothagudem',
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    'district'
  ],
  [
    'Hyderabad',
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    'district'
  ],
  [
    'Jagitial',
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    'district'
  ],
  [
    'Jangaon',
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    'district'
  ],
  [
    'Jayashankar Bhupalpally',
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    'district'
  ],
  [
    'Jogulamba Gadwal',
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    'district'
  ],
  [
    'Kamareddy',
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    'district'
  ],
  [
    'Karimnagar',
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    'district'
  ],
  [
    'Khammam',
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    'district'
  ],
  [
    'Komaram Bheem',
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    'district'
  ],
  [
    'Mahabubabad',
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    'district'
  ],
  [
    'Mahabubnagar',
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    'district'
  ],
  [
    'Mancherial',
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    'district'
  ],
  [
    'Medak',
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    'district'
  ],
  [
    'Medchal Malkajgiri',
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    'district'
  ],
  [
    'Mulugu',
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    'district'
  ],
  [
    'Nagarkurnool',
    9.1,
    1.7,
    0.6,
    77.3,
    9.1,
    1.7,
    0.6,
    77.3,
    9.1,
    1.7,
    0.6,
    77.3,
    9.1,
    1.7,
    0.6,
    77.3,
    9.1,
    1.7,
    0.6,
    77.3,
    9.1,
    1.7,
    0.6,
    77.3,
    'district'
  ],
  [
    'Narayanpet',
    2.8,
    1.4,
    0.8,
    45.9,
    2.8,
    1.4,
    0.8,
    45.9,
    2.8,
    1.4,
    0.8,
    45.9,
    2.8,
    1.4,
    0.8,
    45.9,
    2.8,
    1.4,
    0.8,
    45.9,
    2.8,
    1.4,
    0.8,
    45.9,
    'district'
  ],
  [
    'Nalgonda',
    7.6,
    1.3,
    3.1,
    35.7,
    7.6,
    1.3,
    3.1,
    35.7,
    7.6,
    1.3,
    3.1,
    35.7,
    7.6,
    1.3,
    3.1,
    35.7,
    7.6,
    1.3,
    3.1,
    35.7,
    7.6,
    1.3,
    3.1,
    35.7,
    'district'
  ],
  [
    'Nirmal',
    5.9,
    1.9,
    1.0,
    18.1,
    5.9,
    1.9,
    1.0,
    18.1,
    5.9,
    1.9,
    1.0,
    18.1,
    5.9,
    1.9,
    1.0,
    18.1,
    5.9,
    1.9,
    1.0,
    18.1,
    5.9,
    1.9,
    1.0,
    18.1,
    'district'
  ],
  [
    'Nizamabad',
    4.2,
    1.1,
    2.1,
    40.9,
    4.2,
    1.1,
    2.1,
    40.9,
    4.2,
    1.1,
    2.1,
    40.9,
    4.2,
    1.1,
    2.1,
    40.9,
    4.2,
    1.1,
    2.1,
    40.9,
    4.2,
    1.1,
    2.1,
    40.9,
    'district'
  ],
  [
    'Peddapalli',
    8.1,
    1.3,
    1.9,
    81.8,
    8.1,
    1.3,
    1.9,
    81.8,
    8.1,
    1.3,
    1.9,
    81.8,
    8.1,
    1.3,
    1.9,
    81.8,
    8.1,
    1.3,
    1.9,
    81.8,
    8.1,
    1.3,
    1.9,
    81.8,
    'district'
  ],
  [
    'Rajanna Sircilla',
    6.2,
    1.0,
    1.1,
    43.1,
    6.2,
    1.0,
    1.1,
    43.1,
    6.2,
    1.0,
    1.1,
    43.1,
    6.2,
    1.0,
    1.1,
    43.1,
    6.2,
    1.0,
    1.1,
    43.1,
    6.2,
    1.0,
    1.1,
    43.1,
    'district'
  ],
  [
    'Ranga Reddy',
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    9.1,
    0.3,
    1.2,
    65.9,
    'district'
  ],
  [
    'Sangareddy',
    2.5,
    0.9,
    1.6,
    53.4,
    2.5,
    0.9,
    1.6,
    53.4,
    2.5,
    0.9,
    1.6,
    53.4,
    2.5,
    0.9,
    1.6,
    53.4,
    2.5,
    0.9,
    1.6,
    53.4,
    2.5,
    0.9,
    1.6,
    53.4,
    'district'
  ],
  [
    'Siddipet',
    8.6,
    0.7,
    0.1,
    64.7,
    8.6,
    0.7,
    0.1,
    64.7,
    8.6,
    0.7,
    0.1,
    64.7,
    8.6,
    0.7,
    0.1,
    64.7,
    8.6,
    0.7,
    0.1,
    64.7,
    8.6,
    0.7,
    0.1,
    64.7,
    'district'
  ],
  [
    'Suryapet',
    8.9,
    0.2,
    0.4,
    69.1,
    8.9,
    0.2,
    0.4,
    69.1,
    8.9,
    0.2,
    0.4,
    69.1,
    8.9,
    0.2,
    0.4,
    69.1,
    8.9,
    0.2,
    0.4,
    69.1,
    8.9,
    0.2,
    0.4,
    69.1,
    'district'
  ],
  [
    'Vikarabad',
    7.1,
    0.3,
    1.5,
    53.7,
    7.1,
    0.3,
    1.5,
    53.7,
    7.1,
    0.3,
    1.5,
    53.7,
    7.1,
    0.3,
    1.5,
    53.7,
    7.1,
    0.3,
    1.5,
    53.7,
    7.1,
    0.3,
    1.5,
    53.7,
    'district'
  ],
  [
    'Wanaparthy',
    6.3,
    0.9,
    0.3,
    46.7,
    6.3,
    0.9,
    0.3,
    46.7,
    6.3,
    0.9,
    0.3,
    46.7,
    6.3,
    0.9,
    0.3,
    46.7,
    6.3,
    0.9,
    0.3,
    46.7,
    6.3,
    0.9,
    0.3,
    46.7,
    'district'
  ],
  [
    'Warangal Rural',
    6.2,
    1.7,
    0.2,
    39.5,
    6.2,
    1.7,
    0.2,
    39.5,
    6.2,
    1.7,
    0.2,
    39.5,
    6.2,
    1.7,
    0.2,
    39.5,
    6.2,
    1.7,
    0.2,
    39.5,
    6.2,
    1.7,
    0.2,
    39.5,
    'district'
  ],
  [
    'Warangal Urban/',
    7.1,
    1.1,
    1.7,
    54.0,
    7.1,
    1.1,
    1.7,
    54.0,
    7.1,
    1.1,
    1.7,
    54.0,
    7.1,
    1.1,
    1.7,
    54.0,
    7.1,
    1.1,
    1.7,
    54.0,
    7.1,
    1.1,
    1.7,
    54.0,
    'district'
  ],
  [
    'Yadadri Bhuvanagiri',
    4.2,
    2.9,
    0.1,
    28.3,
    4.2,
    2.9,
    0.1,
    28.3,
    4.2,
    2.9,
    0.1,
    28.3,
    4.2,
    2.9,
    0.1,
    28.3,
    4.2,
    2.9,
    0.1,
    28.3,
    4.2,
    2.9,
    0.1,
    28.3,
    'district'
  ]
];
export const aDailyDetailsData = [
  [
    'Adilabad Rural',
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    8.2,
    0.2,
    1.1,
    55.2,
    'mandal'
  ],
  [
    'Bazarhatnoor',
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    7.6,
    1.1,
    2.2,
    75.2,
    'mandal'
  ],
  [
    'Bela',
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    4.2,
    0.2,
    3.1,
    19.2,
    'mandal'
  ],
  [
    'Bheempur',
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    9.2,
    3.1,
    0.2,
    65.5,
    'mandal'
  ],
  [
    'Boath',
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    5.1,
    0.5,
    0.7,
    42.9,
    'mandal'
  ],
  [
    'Gadiguda',
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    9.2,
    1.2,
    0.6,
    29.3,
    'mandal'
  ],
  [
    'Gudihatnur',
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    8.7,
    1.6,
    1.1,
    69.3,
    'mandal'
  ],
  [
    'Ichoda',
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    7.6,
    1.7,
    1.5,
    45.9,
    'mandal'
  ],
  [
    'Indervelly',
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    8.6,
    1.1,
    0.7,
    82.1,
    'mandal'
  ],
  [
    'Jainath',
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    7.2,
    0.4,
    0.2,
    62.0,
    'mandal'
  ],
  [
    'Mavala',
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    6.2,
    0.8,
    0.6,
    45.3,
    'mandal'
  ],
  [
    'Narnoor',
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    5.3,
    1.1,
    0.4,
    32.9,
    'mandal'
  ],
  [
    'Neradigonda',
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    8.6,
    0.1,
    2.9,
    70.6,
    'mandal'
  ],
  [
    'Sirikonda',
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    5.2,
    1.0,
    2.2,
    37.8,
    'mandal'
  ],
  [
    'Talamadugu',
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    9.1,
    1.4,
    2.1,
    75.3,
    'mandal'
  ],
  [
    'Tamsi',
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    4.2,
    1.1,
    0.3,
    22.1,
    'mandal'
  ],
  [
    'Utnoor',
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    2.1,
    1.6,
    0.5,
    47.9,
    'mandal'
  ]
];

const liveHeadings = [
  'Name',
  'CEO Target Vs Actual %',
  'CEO Scheduled Vs Actual %',
  'CEO Filling Rate %',
  'Dy. CEO Target Vs Actual %',
  'Dy. CEO Scheduled Vs Actual %',
  'Dy. CEO Filling Rate %',
  'DPO Target Vs Actual %',
  'DPO Scheduled Vs Actual %',
  'DPO Filling Rate %',
  'DLPO Target Vs Actual %',
  'DLPO Scheduled Vs Actual %',
  'DLPO Filling Rate %',
  'MPDO Target Vs Actual %',
  'MPDO Scheduled Vs Actual %',
  'MPDO Filling Rate %',
  'MPO Target Vs Actual %',
  'MPO Scheduled Vs Actual %',
  'MPO Filling Rate %'
];
export const testLiveData = {
  All: {
    details: dailyDetailsData,
    heading: liveHeadings
  },
  Adilabad: {
    details: aDailyDetailsData,
    heading: liveHeadings
  }
};
