import { Row, Col, Button } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import { React } from 'react';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function clearItem(type) {
  if (type === 'District') {
    clearItem('Mandal');
    console.log('District cleared');
  }
  if (type === 'Mandal') {
    clearItem('Panchayat');
    console.log('Mandal cleared');
  }
  if (type === 'Panchayat') {
    console.log('Panchayat cleared');
  }
  alert(type + ' cleared');
}

const LItem = ({ tHeading, value, color }) => {
  return (
    <>
      <Col>
        <Flex justifyContent="center">
          <h6 className={`text-${color} fs--2`}>{tHeading}</h6>
          <br />
          <SoftBadge pill bg={color} className="mb-2 mt-2">
            <h5 className="fs-bold">{value !== undefined ? value : 'All'}</h5>
          </SoftBadge>
          <Button
            disabled={value === undefined || value === 'All'}
            size="sm"
            variant="text"
            className="fs--2 p-1 m-0 "
            onClick={() => {
              clearItem(tHeading);
            }}
          >
            <FontAwesomeIcon
              icon={['fa', 'trash']}
              transform="shrink-2"
              className={`text-${color} me-2`}
            />
          </Button>
        </Flex>
      </Col>
      ;
    </>
  );
};

/* function clearFilter() {} */

const Location = ({ stateParm }) => {
  if (stateParm === undefined || stateParm === null) {
    stateParm = {};
  }
  return (
    <>
      <Row className="g-3 mb-3 bg-soft-primary">
        <LItem
          tHeading={'District'}
          value={stateParm.district}
          color="success"
        />
        <LItem tHeading={'Mandal'} value={stateParm.mandal} color="warning" />
        <LItem
          tHeading={'Panchayat'}
          value={stateParm.panchayat}
          color="danger"
        />
        {/* <Col>
          <Flex justifyContent="center">
            <h6 className="fs--2 text-success">District</h6>
            <br />
            <SoftBadge pill bg="success" className="mb-2 mt-2">
              <h5 className="fs-bold">
                {stateParm.district !== undefined ? stateParm.district : 'All'}
              </h5>
            </SoftBadge>
            <Button size="sm" variant="text" className="fs--2" disableElevation>
              <FontAwesomeIcon
                icon={['fa', 'minus']}
                transform="shrink-2"
                className="ms-1 text-success"
              />
            </Button>
          </Flex>
        </Col> */}
        {/* <Col>
          <Flex justifyContent="center">
            <span>
              <h6 className="fs--2 text-warning-color">Mandal</h6>
            </span>
            <SoftBadge pill bg="warning" className="mb-2 mt-2">
              <h5>
                {stateParm.mandal !== undefined ? stateParm.mandal : 'All'}
              </h5>
            </SoftBadge>
          </Flex>
        </Col>
        <Col>
          <Flex justifyContent="center">
            <span>
              <h6 className="fs--2 text-warning-color">Panchayat</h6>
            </span>
            <SoftBadge pill bg="danger" className="mb-2 mt-2">
              <h5>
                {stateParm.panchayat !== undefined
                  ? stateParm.panchayat
                  : 'All'}
              </h5>
            </SoftBadge>
          </Flex>
        </Col> */}
      </Row>
    </>
  );
};
Location.propTypes = {
  stateParm: PropTypes.any
};

LItem.propTypes = {
  tHeading: PropTypes.string.isRequired,
  value: PropTypes.any,
  color: PropTypes.string.isRequired
};

export default Location;
