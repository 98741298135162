import React from 'react';
import PropTypes from 'prop-types';
import HBoards from './HBoardItem';
import { Table } from 'react-bootstrap';

const HBoardTable = ({ heading, aData }) => {
  return (
    <>
      <Table responsive>
        <thead className="bg-light">
          <tr className="text-900">
            {heading.map((hItem, index) => (
              <th key={index} className="white-space-nowrap">
                {hItem}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <HBoards top5Data={aData.Top5} bottom5Data={aData.Bottom5} />
        </tbody>
      </Table>
    </>
  );
};

HBoardTable.propTypes = {
  heading: PropTypes.array.isRequired,
  aData: PropTypes.shape({
    Top5: PropTypes.any.isRequired,
    Bottom5: PropTypes.any.isRequired
  })
};
export default HBoardTable;
