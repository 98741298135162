import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { scores } from 'data/dashboard/gpdata';
import ScoreWidget from '../utils/scoreWidget';
import { useLocation } from 'react-router-dom';

import Location from '../utils/Location';

const Dashboard = () => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <Location stateParm={state} />

      <Row className="g-3 mb-3">
        <Col lg={4}>
          <ScoreWidget
            dataDump={scores.psScore}
            heading="PS Attendance"
            link="/dashboard/psscore"
            curState={state}
          />
        </Col>

        <Col lg={4}>
          <ScoreWidget
            dataDump={scores.dsrScore}
            heading="Inspection Compliance"
            link="/dashboard/inspection-compliance"
            curState={state}
          />
        </Col>
        <Col lg={4}>
          <ScoreWidget
            dataDump={scores.gpScore}
            heading="GP Score"
            link="/dashboard/gpscore"
            curState={state}
          />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
