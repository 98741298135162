import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import greetingImage from '../../../assets/img/illustrations/crm-bar-chart.png';
import PropTypes from 'prop-types';
/* import greetingsBg from '../../../assets/img/illustrations/ticket-greetings-bg.png'; */
import Background from 'components/common/Background';
import { useDate } from '../utils/pageutils';

function Greetings({ heading = 'Month till date' }) {
  const { date, time } = useDate();
  return (
    <Card className="bg-light shadow-none">
      <Background image={greetingImage} className="bg-card d-none d-sm-block" />
      <Card.Body>
        <Row>
          <Col lg={4} className="fs--1 fs-bold justify-content-between">
            {date}{' '}
          </Col>
          <Col lg={4} className="fs-3 d-flex justify-content-center ">
            {heading}{' '}
          </Col>
          <Col lg={4} className="fs--1 fs-bold d-flex justify-content-end">
            {time}{' '}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

Greetings.propTypes = {
  heading: PropTypes.string.isRequired
};
export default Greetings;
