import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import HBoardTable from './HBoardTable';

const CWidget = ({ Wtitle, ParmHeading, aData }) => {
  return (
    <>
      <Accordion.Header>{Wtitle}</Accordion.Header>
      <AccordionBody>
        <Row>
          <Col lg={4}>
            <Card className="h-100">
              <Card.Header>District</Card.Header>
              <Card.Body className="p-0">
                <HBoardTable
                  heading={['S. No', 'District', ParmHeading]}
                  aData={aData.district}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="h-100">
              <Card.Header>Mandal</Card.Header>
              <Card.Body className="p-0">
                <HBoardTable
                  heading={['S. No', 'District', 'Mandal', ParmHeading]}
                  aData={aData.mandal}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="h-100">
              <Card.Header>GP</Card.Header>
              <Card.Body className="p-0">
                <HBoardTable
                  heading={['S. No', 'District', 'Mandal', 'GP', ParmHeading]}
                  aData={aData.gp}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </AccordionBody>
    </>
  );
};

CWidget.propTypes = {
  Wtitle: PropTypes.string.isRequired,
  ParmHeading: PropTypes.string.isRequired,
  aData: PropTypes.any.isRequired
};

export default CWidget;
