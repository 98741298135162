import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { psAttendanceData } from 'data/dashboard/gpdata';

import Accordion from 'react-bootstrap/Accordion';
import CWidget from './CategoryWidget';

const HBoardPage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <CWidget
                Wtitle="PS Attendance"
                ParmHeading="Attendance %"
                aData={psAttendanceData}
              />
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <CWidget
                Wtitle="Inspection Compliance"
                ParmHeading="Inspection %"
                aData={psAttendanceData}
              />
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <CWidget
                Wtitle="GP Score"
                ParmHeading="GP Score"
                aData={psAttendanceData}
              />
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default HBoardPage;
