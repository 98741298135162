import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import AttendanceChart from './AttendanceChart';

import Location from '../utils/Location';
import {
  atttendanceDistribution,
  daysPresent,
  attendanceSummary,
  monthlySummaryTable,
  historyTable,
  DSRFilingStatus,
  monthlySummaryHeading,
  statusData
} from 'data/dashboard/gpdata';
import DaysPresent from './DaysPresent';
import AttendanceSummary from './AttendanceSummary';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';

import Accordion from 'react-bootstrap/Accordion';
import HistoryChart from './HistoryChart';
import { useLocation } from 'react-router-dom';

import DetailsTable from '../utils/DailyDetails';
import LiveStats from '../live/LiveStats';

const PSScore = () => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <Location stateParm={state} />

      <Col lg={{ order: 'first' }} xs={{ order: 'first' }}>
        <LiveStats data={statusData} prevText="last month" />
      </Col>

      <Row className="g-3 mb-3 d-flex justify-content-center">
        <Col lg={{ order: '1', span: 6 }}>
          <Card className="h-md-100">
            <Card.Body alignItems="between">
              <AttendanceSummary datadump={attendanceSummary} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ order: '3', span: 6 }}>
          <Card className="h-md-100">
            <Card.Body alignItems="between">
              <DaysPresent datadump={daysPresent} heading="Days Present" />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ order: '4', span: 6 }}>
          <Card className="h-md-100">
            <Card.Body alignItems="between">
              <AttendanceChart datadump={atttendanceDistribution} />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={{ order: '5', span: 6 }}>
          <Card className="h-md-100">
            <Card.Body alignItems="between">
              <DaysPresent datadump={DSRFilingStatus} heading="DSR Filed" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Card className="h-md-100">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Details</Accordion.Header>
              <AccordionBody>
                <DetailsTable
                  heading={monthlySummaryHeading}
                  data={monthlySummaryTable}
                />
              </AccordionBody>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>History</Accordion.Header>
              <AccordionBody>
                <HistoryChart datadump={historyTable} />
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Row>
    </>
  );
};

export default PSScore;
